.token.tag,
.token.class-name,
.token.selector,
.token.selector .class,
.token.function {
  color: #e879f9;
}

.token.attr-name,
.token.keyword,
.token.rule,
.token.operator,
.token.pseudo-class,
.token.important {
  color: #22d3ee;
}

.token.attr-value,
.token.class,
.token.string,
.token.number,
.token.unit,
.token.color {
  color: #bef264;
}

.token.punctuation,
.token.module,
.token.property {
  color: #bae6fd;
}

.token.atapply .token:not(.rule):not(.important) {
  color: inherit;
}

.language-shell .token:not(.comment) {
  color: inherit;
}

.language-css .token.function {
  color: inherit;
}

.token.comment {
  @apply text-gray-400;
}

.token.deleted:not(.prefix) {
  @apply relative block -mx-4 px-4;
}

.token.deleted:not(.prefix)::after {
  content: "";
  background: #fb7185;
  @apply pointer-events-none absolute inset-0 block  bg-opacity-25;
}

.token.deleted.prefix {
  @apply text-gray-400 select-none;
}

.token.inserted:not(.prefix) {
  background: #064e3b;
  @apply block bg-opacity-50 -mx-4 px-4;
}

.token.inserted.prefix {
  color: #a7f3d0;
  @apply text-opacity-75 select-none;
}
