@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.prism-highlight::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.prism-highlight::-webkit-scrollbar-track {
  background: transparent;
  border: unset;
  width: 0;
}
.prism-highlight::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.48);
  border-radius: 3px;
}
.prism-highlight::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: unset;
  width: 0;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
